<template>
  <el-carousel height="10.65rem" :interval="5000">
    <el-carousel-item v-for="(item, i) in data" :key="i">
      <!-- <img :src="item.img" alt="" style="width: 100%; height: 100%" /> -->
      <div class="pic_item">
        <img class="small" :src="item.img" />
        <h3 id="title" @click="go(i)">{{ item.title }}</h3>
        <p class="text" :id="'text' + i">{{ item.text }}</p>
        <!-- <a class="route"></a> -->
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    go(index) {
      sessionStorage.setItem("num", 2);
      // console.log(index);
      switch (index) {
        case 1:
          this.$router.push({ path: "/tea" });
          break;
        case 2:
          this.$router.push({ path: "/health" });
          break;
        case 3:
          this.$router.push({ path: "/industry" });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 750px) {
  .text {
    position: absolute;
    opacity: 1;
    // top: 5.39rem;
    // left: 5.58rem;
    font-size: 0.41rem;
    font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
    font-weight: 400;
    color: #ffffff;
    line-height: 9px;
    text-shadow: 0px 1px 1px #0f294b;
    margin-top: 3rem;
  }
  #title {
    font-size: 1.02rem;
    font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
    font-weight: bold;
    color: #ffffff;
    line-height: 1.18rem;
    text-shadow: 0px 1px 1px #11294a;
    position: absolute;
    opacity: 1;
    margin-top: 0.5rem;
    text-align: center;
  }

  .route {
    display: block;
    width: 4rem;
    height: 0.8rem;
    position: absolute;
    cursor: pointer;
    // top: 7rem;
    // left: 7.56rem;
    margin-top: 5rem;
    // background: url(../../../assets/img/Component5.png);
    // background-size: 100% 100%;
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .text {
    position: absolute;
    opacity: 1;
    /* top: 5.39rem;
    left: 5.7rem; */
    /* width: 8.5rem; */
    font-size: 0.7rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.47rem;
    text-shadow: 0 0.04rem 0.04rem #102a4b;
    margin-top: 1.1rem;
  }
  #text0 {
    font-size: 1.2rem;
  }
  #title {
    width: 15rem;
    height: 1.4rem;
    font-size: 1rem;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #fff;
    line-height: 1.17rem;
    text-shadow: 0rem 0.04rem 0.04rem #112a4a;
    position: absolute;
    opacity: 1;
    margin-top: -2rem;
    /* top: 3.88rem;
    left: 2.45rem; */
    text-align: center;
  }

  .route {
    display: block;
    width: 4rem;
    height: 0.8rem;
    position: absolute;
    margin-top: 2rem;
    cursor: pointer;
    /* top: 6.54rem;
    left: 7.56rem; */
    // background: url(../../../assets/img/Component3.png);
    // background-size: 100% 100%;
  }
}

.pic_item {
  position: relative;
  background: url(../../../assets/img/banner0.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .small {
    width: 100%;
    height: 100%;
  }
}
::v-deep .el-carousel__arrow {
  font-size: 0.5rem;
  width: 0.6rem;
  height: 0.6rem;
  text-align: center;
}
.el-carousel__arrow i {
  font-size: 0.5rem;
  cursor: pointer;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 0.18rem;
  opacity: 0.75;
  line-height: 3rem;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
